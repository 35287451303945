<template>
    <div class="making-order">
        <section
            id="making-order"
            :class="success ? 'no-margin' : ''"
            :style="
                success
                    ? 'background-image: linear-gradient(0deg, transparent 0%, rgba(255,255,255,0.8) 0%), url(' +
                      require('@/assets/img/success-bg.png') +
                      ')'
                    : ''
            "
        >
            <div v-if="success" class="success-making-order">
                <div class="container">
                    <ph-check class="success-icon" />
                    <div class="success-making-order-text">
                        <h1>Заказ успешно оформлен!</h1>
                        <p>
                            Спасибо за заказ! Наши сотрудники свяжутся с вами в
                            течении 30 минут.
                        </p>
                    </div>
                    <Button :link="'/'">На главную</Button>
                </div>
            </div>
            <div class="container making-order__wrapper" v-else>
                <h1>Оформление заказа</h1>
                <n-form
                    class="making-order-content"
                    ref="formRef"
                    :model="form_data"
                    :rules="rules"
                    :show-label="false"
                >
                    <div class="making-order-left">
                        <div class="making-order-left-block">
                            <h3>Доставка</h3>
                            <n-radio-group
                                class="making-order-left-deliveries"
                                v-model:value="delivery_index"
                            >
                                <n-radio
                                    :value="index"
                                    class="making-order-left-block-item"
                                    v-for="(item, index) in deliveries"
                                >
                                    <div
                                        class="making-order-left-block-item-info"
                                    >
                                        <h5>{{ item.title }}</h5>
                                        <div
                                            class="making-order-left-block-item-subtitle"
                                            v-html="item.text"
                                        ></div>
                                    </div>
                                    <h5 v-if="item.value != '0'">
                                        {{
                                            numberWithSpaces(item.value) + " ₽"
                                        }}
                                    </h5>
                                    <h5 v-else>&mdash;</h5>
                                </n-radio>
                            </n-radio-group>
                        </div>
                        <div class="making-order-left-block">
                            <h3>Выберите дату и время доставки</h3>
                            <div class="making-order-left-block-two-columns">
                                <n-form-item path="date">
                                    <n-date-picker
                                        :month-format="'MMMM'"
                                        style="width: 100%"
                                        placement="bottom-end"
                                        class="making-order-date-picker"
                                        placeholder="Выберите дату *"
                                        v-model:value="form_data.date"
                                        :is-date-disabled="dateFilter"
                                        :input-readonly="true"
                                        :actions="['clear']"
                                    >
                                        <template #date-icon>
                                            <PhCalendarBlank />
                                        </template>
                                    </n-date-picker>
                                </n-form-item>
                                <!-- <n-form-item path="time">
                                    <n-select
                                        v-model:value="form_data.time"
                                        :options="options"
                                        placeholder="Выберите время *"
                                    />
                                </n-form-item> -->
                            </div>
                        </div>

                        <div class="making-order-left-block half half-grid">
                            <h3>Адрес</h3>
                            <div class="part-od-form">
                                <n-form-item path="city">
                                    <n-input
                                        v-model:value="form_data.city"
                                        placeholder="Город *"
                                    />
                                </n-form-item>
                                <n-form-item path="street">
                                    <n-input
                                        v-model:value="form_data.street"
                                        placeholder="Улица *"
                                    />
                                </n-form-item>
                                <n-form-item path="house">
                                    <n-input
                                        v-model:value="form_data.house"
                                        placeholder="Дом *"
                                    />
                                </n-form-item>
                                <n-form-item path="entrance">
                                    <n-input
                                        v-model:value="form_data.entrance"
                                        placeholder="Подъезд *"
                                    />
                                </n-form-item>
                                <n-form-item path="floor">
                                    <n-input
                                        v-model:value="form_data.floor"
                                        placeholder="Этаж *"
                                    />
                                </n-form-item>
                                <n-form-item path="flat">
                                    <n-input
                                        v-model:value="form_data.flat"
                                        placeholder="Квартира *"
                                    />
                                </n-form-item>
                                <n-form-item path="comment">
                                    <n-input
                                        rows="10"
                                        type="textarea"
                                        v-model:value="form_data.comment"
                                        placeholder="Комментарии к заказу"
                                    />
                                </n-form-item>
                            </div>
                        </div>
                        <div class="making-order-left-block two-thirds">
                            <h3>Получатель</h3>
                            <div>
                                <n-form-item path="last_name">
                                    <n-input
                                        v-model:value="form_data.last_name"
                                        placeholder="Фамилия *"
                                    />
                                </n-form-item>
                                <n-form-item path="name">
                                    <n-input
                                        v-model:value="form_data.name"
                                        placeholder="Имя *"
                                    />
                                </n-form-item>
                                <n-form-item path="phone">
                                    <n-input
                                        v-model:value="form_data.phone"
                                        placeholder="Телефон *"
                                    />
                                </n-form-item>
                                <n-form-item path="email">
                                    <n-input
                                        v-model:value="form_data.email"
                                        placeholder="Email *"
                                    />
                                </n-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="making-order__total">
                        <h3 class="making-order__total-title">Ваш заказ</h3>
                        <div class="making-order__total-products">
                            <div
                                class="making-order__total-product"
                                v-for="product in order"
                            >
                                <div class="making-order__total-product-info">
                                    <div
                                        class="making-order__total-product-info-header"
                                    >
                                        <h4>
                                            {{ product.title }}
                                            <span>x {{ product.count }}</span>
                                        </h4>
                                        <h4>
                                            {{
                                                numberWithSpaces(
                                                    product.final_price
                                                ) + " ₽"
                                            }}
                                        </h4>
                                    </div>
                                    <div
                                        class="making-order__total-product-info-desc"
                                        v-if="product.size"
                                    >
                                        <div>
                                            Размер:
                                            <span>
                                                {{ product?.info?.size }}
                                            </span>
                                        </div>
                                        <div>
                                            Комплектация:
                                            <span
                                                v-if="product?.info?.complect"
                                            >
                                                комплект
                                            </span>
                                            <span v-else>полотно</span>
                                        </div>
                                    </div>
                                </div>
                                <h4>
                                    {{
                                        numberWithSpaces(product.final_price) +
                                        " ₽"
                                    }}
                                </h4>
                            </div>
                        </div>
                        <div class="making-order__total-promo">
                            <h5>Промокод или купон на скидку</h5>
                            <n-form
                                :show-label="false"
                                ref="promoFormRef"
                                :model="promo_data"
                                :rules="promo_rules"
                                style="
                                    display: flex;
                                    flex-direction: column;
                                    gap: 10px;
                                "
                            >
                                <n-form-item path="promo">
                                    <n-input
                                        placeholder="Промокод"
                                        v-model:value="promo_data.promo"
                                    />
                                </n-form-item>
                                <n-form-item>
                                    <Button
                                        class="promo-btn"
                                        @click="checkPromo"
                                    >
                                        Применить
                                    </Button>
                                </n-form-item>
                            </n-form>
                        </div>
                        <div class="making-order__total-prices">
                            <div class="making-order__total-price products">
                                <div>Сумма по товарам</div>
                                <h4>
                                    {{
                                        numberWithSpaces(products_total_price) +
                                        "  ₽"
                                    }}
                                </h4>
                            </div>
                            <div class="making-order__total-price delivery">
                                <div>Стоимость доставки</div>
                                <h4 v-if="deliveries[delivery_index]?.value">
                                    {{
                                        numberWithSpaces(
                                            deliveries[delivery_index]?.value
                                        ) + "  ₽"
                                    }}
                                </h4>
                                <h4 v-else>&mdash;</h4>
                            </div>
                            <div class="making-order__total-price discount">
                                <div>Стоимость скидки</div>
                                <h4>
                                    {{
                                        promo_info.promo
                                            ? promo_sale_percent + "  ₽"
                                            : "0" + "  ₽"
                                    }}
                                </h4>
                            </div>
                        </div>
                        <div class="making-order__total-total">
                            <h4>Итого</h4>
                            <h4>{{ total_price + "  ₽" }}</h4>
                        </div>
                        <Button
                            @click="createOrder()"
                            class="making-order__total-btn"
                            type="white"
                        >
                            Подтвердить заказ
                        </Button>
                    </div>
                </n-form>
            </div>
        </section>
    </div>
</template>
<script setup>
import {
    NInput,
    NForm,
    NFormItem,
    NDatePicker,
    NRadio,
    NRadioGroup,
    NSelect,
    useMessage,
    NAlert,
} from "naive-ui";
import { PhCalendarBlank, PhCheck } from "@phosphor-icons/vue";
import { computed, h, nextTick, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import {
    numberWithSpaces,
    validateEmail,
    replaceNumberForPaste,
    YMTargetSend,
} from "@/assets/js/helper.js";
import Button from "@/components/UI/Button.vue";
import productApi from "@/assets/js/api/products.js";
import orderApi from "@/assets/js/api/order.js";
import userApi from "@/assets/js/api/user.js";

const store = useStore();
const message_error = useMessage();
const pattern = /[а-яА-ЯЁёa-zA-Z@ ]*$/g;

const options = [
    {
        label: "00:00 - 01:00",
        value: "00:00 - 01:00",
    },
    {
        label: "01:00 - 02:00",
        value: "01:00 - 02:00",
    },
    {
        label: "02:00 - 03:00",
        value: "02:00 - 03:00",
    },
    {
        label: "03:00 - 04:00",
        value: "03:00 - 04:00",
    },
    {
        label: "04:00 - 05:00",
        value: "04:00 - 05:00",
    },
    {
        label: "05:00 - 06:00",
        value: "05:00 - 06:00",
    },
    {
        label: "06:00 - 07:00",
        value: "06:00 - 07:00",
    },
    {
        label: "07:00 - 08:00",
        value: "07:00 - 08:00",
    },
    {
        label: "08:00 - 09:00",
        value: "08:00 - 09:00",
    },
    {
        label: "09:00 - 10:00",
        value: "09:00 - 10:00",
    },
    {
        label: "10:00 - 11:00",
        value: "10:00 - 11:00",
    },
    {
        label: "11:00 - 12:00",
        value: "11:00 - 12:00",
    },
    {
        label: "12:00 - 13:00",
        value: "12:00 - 13:00",
    },
    {
        label: "13:00 - 14:00",
        value: "13:00 - 14:00",
    },
    {
        label: "14:00 - 15:00",
        value: "14:00 - 15:00",
    },
    {
        label: "15:00 - 16:00",
        value: "15:00 - 16:00",
    },
    {
        label: "16:00 - 17:00",
        value: "16:00 - 17:00",
    },
    {
        label: "17:00 - 18:00",
        value: "17:00 - 18:00",
    },
    {
        label: "18:00 - 19:00",
        value: "18:00 - 19:00",
    },
    {
        label: "19:00 - 20:00",
        value: "19:00 - 20:00",
    },
    {
        label: "20:00 - 21:00",
        value: "20:00 - 21:00",
    },
    {
        label: "21:00 - 22:00",
        value: "21:00 - 22:00",
    },
    {
        label: "22:00 - 23:00",
        value: "22:00 - 23:00",
    },
    {
        label: "23:00 - 00:00",
        value: "23:00 - 00:00",
    },
];

const success = ref(false);

store.commit("setBreadcrumb", [
    { name: "Главная", link: "/" },
    { name: "Оформление заказа" },
]);

watch(success, (new_value) => {
    if (new_value) {
        store.commit("setBreadcrumb", null);
        store.state.scrollItem.scrollTo({ top: 0, behavior: "smooth" });
    } else {
        store.commit("setBreadcrumb", [
            { name: "Главная", link: "/" },
            { name: "Оформление заказа" },
        ]);
    }
});

const formRef = ref(null);
const form_data = ref({
    date: null,
    time: null,
    city: null,
    street: null,
    house: null,
    entrance: null,
    comment: null,
    last_name: null,
    name: null,
    phone: null,
    email: null,
    comment: null,
    floor: null,
    flat: null,
});
const rules = {
    date: {
        type: "number",
        required: true,
        trigger: ["blur", "change"],
        message: "Обязательное поле",
    },
    time: {
        required: true,
        trigger: ["blur", "change"],
        message: "Обязательное поле",
    },
    city: {
        required: true,
        trigger: ["input", "blur"],
        message: "Обязательное поле",
    },
    street: {
        required: true,
        trigger: ["input", "blur"],
        message: "Обязательное поле",
    },
    house: {
        required: true,
        trigger: ["input", "blur"],
        message: "Обязательное поле",
    },
    entrance: {
        required: true,
        trigger: ["input", "blur"],
        message: "Обязательное поле",
    },
    house: {
        required: true,
        trigger: ["input", "blur"],
        message: "Обязательное поле",
    },
    flat: {
        required: true,
        trigger: ["input", "blur"],
        message: "Обязательное поле",
    },
    floor: {
        required: true,
        trigger: ["input", "blur"],
        message: "Обязательное поле",
    },
    last_name: {
        required: true,
        trigger: ["input", "blur"],
        message: "Обязательное поле",
    },
    name: {
        required: true,
        trigger: ["input", "blur"],
        message: "Обязательное поле",
    },
    phone: {
        required: true,
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Обязательное поле");
            } else {
                form_data.value.phone = replaceNumberForPaste(value);
                if (value.length < 18) {
                    return new Error("Введите существующий телефон");
                }
            }
        },
    },
    email: {
        required: true,
        trigger: ["input", "blur"],
        validator(rule, value) {
            if (!value) {
                return new Error("Обязательное поле");
            }
            if (value.charAt().match(pattern) != null) {
                if (!validateEmail(value)) {
                    return new Error("Введите существующую почту");
                }
            }
        },
    },
};

const promoFormRef = ref(null);
const promo_data = ref({
    promo: "",
});
const promo_rules = {
    promo: {
        validator: (rule, value) => {
            return new Promise(async (resolve, reject) => {
                if (value == "") {
                    return;
                } else {
                    const result = await orderApi.checkPromo({ promo: value });

                    if (result.success) {
                        promo_info.value = result?.promo;
                        resolve();
                    } else {
                        reject(Error("Неверный промокод/купон"));
                    }
                }
            });
        },
    },
};
const promo_info = ref({
    promo: "",
});

const deliveries = ref([]);
const delivery_index = ref(0);

const products_total_price = ref(null);
const total_price = computed(() => {
    if (promo_info.value) {
        if (promo_info.value.is_delivery) {
            return numberWithSpaces(
                deliveries.value[delivery_index.value]?.value +
                    products_total_price.value -
                    (deliveries.value[delivery_index.value]?.value / 100) *
                        promo_info.value?.percent
            );
        } else if (promo_info.value.is_money) {
            return numberWithSpaces(
                deliveries.value[delivery_index.value]?.value +
                    products_total_price.value -
                    (products_total_price.value / 100) *
                        promo_info.value?.percent
            );
        }
    }
    return numberWithSpaces(
        deliveries.value[delivery_index.value]?.value +
            products_total_price.value
    );
});

const order = ref([]);

async function init() {
    store.commit("setLoading", true);
    const result = await productApi.getInfoDraft();

    if (result) {
        store.commit("setLoading", false);
    }
    if (result?.success) {
        order.value = result.products;
        products_total_price.value = result.order_price;
        deliveries.value = result.deliveries;
    }
}

init();

async function createOrder() {
    const date = new Date();
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);

    const draft_id = JSON.parse(sessionStorage.getItem("draft_id"));

    const data = {
        promo: promo_info.value.promo ?? "",
        id: draft_id,
        order_date: `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`,
        date: form_data.value.date,
        time: form_data.value.time,
        city: form_data.value.city,
        flat: form_data.value.flat,
        phone: form_data.value.phone,
        floor: form_data.value.floor,
        street: form_data.value.street,
        number: form_data.value.house,
        doorway: form_data.value.entrance,
        products: order.value,
        lastname: form_data.value.last_name,
        firstname: form_data.value.name,
        delivery_type: deliveries.value[delivery_index.value].title ?? "",
        comment: form_data.value.comment ?? "",
        delivery_price: deliveries.value[delivery_index.value].value ?? "",
    };

    await formRef.value.validate(async (errors) => {
        if (!errors) {
            const result = await orderApi.createOrder(data);

            if (result.success) {
                success.value = true;
                let products_ids = [];
                data?.products.forEach((product) => {
                    products_ids.push(product.uniq_id);
                });

                await productApi.delete_from("basket", {
                    date: new Date().getTime(),
                    products: products_ids,
                });

                message_error.success("Заказ успешно отправлен", {
                    render: (props) => {
                        return h(
                            NAlert,
                            {
                                closable: props.closable,
                                onClose: props.onClose,
                                type: "success",
                                style: {
                                    boxShadow: "var(--n-box-shadow)",
                                    maxWidth: "calc(100vw - 32px)",
                                    padding: "14px",
                                    width: "480px",
                                },
                            },
                            {
                                default: () =>
                                    h("div", { innerHTML: props.content }),
                            }
                        );
                    },
                    closable: true,
                    duration: 5000,
                });
                YMTargetSend("zakaz");
            } else {
                message_error.error("Ошибка оформления заказа", {
                    render: (props) => {
                        return h(
                            NAlert,
                            {
                                closable: props.closable,
                                onClose: props.onClose,
                                type: "error",
                                style: {
                                    boxShadow: "var(--n-box-shadow)",
                                    maxWidth: "calc(100vw - 32px)",
                                    padding: "14px",
                                    width: "480px",
                                },
                            },
                            {
                                default: () =>
                                    h("div", { innerHTML: props.content }),
                            }
                        );
                    },
                    closable: true,
                    duration: 5000,
                });
            }
        } else {
            message_error.error("Ошибки данных", {
                render: (props) => {
                    return h(
                        NAlert,
                        {
                            closable: props.closable,
                            onClose: props.onClose,
                            type: "error",
                            style: {
                                boxShadow: "var(--n-box-shadow)",
                                maxWidth: "calc(100vw - 32px)",
                                padding: "14px",
                                width: "480px",
                            },
                        },
                        {
                            default: () =>
                                h("div", { innerHTML: props.content }),
                        }
                    );
                },
                closable: true,
                duration: 5000,
            });
        }
    });
}

async function checkPromo() {
    await promoFormRef.value.validate();
}

const promo_sale_percent = computed(() => {
    if (promo_info.value.is_delivery) {
        if (deliveries.value[delivery_index.value].value != "0") {
            return numberWithSpaces(
                (deliveries.value[delivery_index.value].value / 100) *
                    promo_info.value?.percent
            );
        } else {
            return 0;
        }
    } else if (promo_info.value.is_money) {
        return numberWithSpaces(
            (
                (products_total_price.value / 100) *
                promo_info.value?.percent
            ).toFixed(1)
        );
    }
});

function dateFilter(value) {
    let current_day = new Date();
    current_day = current_day.setDate(current_day.getDate() + 2);
    if (current_day >= value) {
        return true;
    }
}

async function getAddress() {
    const result_address = await userApi.getSettings("address");

    if (result_address?.success) {
        let data = result_address?.result;
        form_data.value.city = data.city;
        form_data.value.street = data.street;
        form_data.value.house = data.number;
        form_data.value.entrance = data.doorway;
        form_data.value.floor = data.floor;
        form_data.value.flat = data.flat;
    }

    const result_user = await userApi.getSettings("user");

    if (result_user?.success) {
        let data = result_user?.result;
        form_data.value.last_name = data.last_name;
        form_data.value.name = data.first_name;
        form_data.value.phone = data.phone;
        form_data.value.email = data.email;
    }
}
getAddress();
</script>
<style>
.making-order__wrapper {
    display: flex;
    flex-direction: column;
    gap: 60px;
}
.making-order-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}
.making-order-left {
    grid-column: 1 / span 2;
}
.making-order__total {
    background-color: var(--black);
    border-radius: 5px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: max-content;
}
.making-order__total h4 {
    font-size: 18px;
    color: var(--white);
}
.making-order__total-title {
    color: var(--white);
    /* padding-bottom: 20px; */
    /* border-bottom: 1px solid gray; */
}
.making-order__total-products {
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
}
.making-order__total-product {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    border-top: 1px solid var(--gray);
}

.making-order__total-product:last-of-type {
    border-bottom: 1px solid var(--gray);
}

.making-order__total-product-info {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.making-order__total-product-info-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
    color: var(--white);
}
.making-order__total-product-info-title span {
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0.045em;
    text-align: right;
    color: var(--white);
}
.making-order__total-product-info-desc {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.making-order__total-product-info-desc div {
    display: inline-flex;
    gap: 5px;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: var(--white);
}
.making-order__total-product-info-desc span {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: var(--white);
}
.making-order__total-product-price {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.045em;
    text-align: left;
    color: var(--white);
}
.making-order__total-promo {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.making-order__total-promo h5 {
    color: var(--white);
}
/* .making-order__total-promo .n-form-item .n-form-item-feedback-wrapper {
    display: none;
} */
.making-order__total-promo .n-input .n-input-wrapper {
    padding: 16px;
}
.making-order__total-prices {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.making-order__total-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.making-order__total-price div {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
    color: var(--white);
}

.making-order__total-total {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid var(--gray);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--white);
}

.making-order-left {
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.making-order-left-block.half {
    max-width: calc(84% / 2 - 15px);
}
.making-order-left-block.half.half-grid {
    max-width: 84%;
}
.making-order-left-block.half.half-grid .part-od-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}
.part-od-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.making-order-left-block {
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
}
.making-order-left-block:has(.making-order-left-block-two-columns),
.making-order-left-block.two-thirds {
    max-width: 84%;
}
.making-order-left-block-two-columns .n-base-selection .n-base-selection-label {
    height: 100%;
}
.making-order-left-block.two-thirds > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}
.making-order-left-block .n-form-item.n-form-item--top-labelled {
    /* grid-template-areas:
        "label"
        "blank"
        "feedback"; */
}
.making-order-left-block .n-form-item .n-form-item-feedback-wrapper {
    position: absolute;
    top: calc(100% + 3px);
    padding: 0;
    font-family: Manrope;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.045em;
}
.making-order-left-block-item {
    display: flex;
    gap: 20px;
}
.making-order-left-block-item.n-radio .n-radio__dot-wrapper {
    margin-top: 3px;
}
.making-order-left-block-item.n-radio .n-radio__label {
    padding: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.making-order-left-block-item-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 70%;
}
.making-order-left-block-item-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
    color: var(--black);
}
.making-order-left-block-item-subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
    color: var(--dark_gray);
}
.making-order-left-block-item-price {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black);
}

.making-order-left-block-two-columns {
    display: flex;
    flex-direction: row;
    gap: 30px;
}
.making-order-left-block-two-columns .n-form-item,
.making-order-left-block .n-form-item {
    width: 100%;
    position: relative;
}
.making-order-left-block-two-columns .n-form-item .n-form-item-label,
.making-order-left-block .n-form-item .n-form-item-label {
    position: absolute;
    align-items: center;
    grid-area: blank;
    z-index: 10;
    pointer-events: none;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.045em;
    text-align: left;
    color: var(--gray);
    top: 50%;
    left: 16px;
    translate: 0 -50%;
    min-height: auto;
    padding: 0;
    transition: 0.5s;
}
.making-order-left-block-two-columns
    .n-date-panel
    .n-date-panel-actions
    .n-date-panel-actions__suffix {
    margin: 0;
}

.n-date-panel:not(.n-date-panel--week) .n-date-panel-dates .n-date-panel-date {
    box-sizing: content-box;
    border: 1px solid transparent;
}

.n-date-panel:not(.n-date-panel--week)
    .n-date-panel-dates
    .n-date-panel-date:not(.n-date-panel-date--selected):hover {
    border: 1px solid var(--black);
    z-index: 5;
}

.n-date-panel:not(.n-date-panel--week)
    .n-date-panel-dates
    .n-date-panel-date.n-date-panel-date--excluded:hover {
    border: 1px solid transparent;
}
/*
.n-date-panel:not(.n-date-panel--week)
    .n-date-panel-dates
    .n-date-panel-date.n-date-panel-date--covered:not(
        .n-date-panel-date--disabled
    ):not(.n-date-panel-date--selected) {
    border: none;
} */
.n-date-panel .n-date-panel-dates {
    row-gap: 4px;
}
.n-date-panel .n-date-panel-actions .n-button {
    background-color: var(--black);
    color: var(--white);
    padding: 5px 20px;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.12px;
    letter-spacing: 0.045em;
    text-align: center;
    border-radius: 5px;
    border: 1px solid transparent;
    height: auto;
}
.n-date-panel
    .n-date-panel-actions
    .n-button:not(.n-button--disabled):hover
    .n-button__state-border {
    display: none;
}
.n-date-panel
    .n-date-panel-actions
    .n-button:not(.n-button--disabled):focus
    .n-button__state-border {
    display: none;
}
.n-date-panel .n-date-panel-actions .n-button:hover {
    border: 1px solid transparent;
    background-color: var(--dark_gray);
}
.n-date-panel .n-date-panel-actions .n-button:active {
    border: 1px solid var(--black);
    background-color: var(--dark_gray);
    box-shadow: 0px 4px 4px 0px #00000040 inset;
}
.n-date-panel .n-date-panel-actions .n-button .n-base-wave {
    display: none;
}
.n-date-panel .n-date-panel-actions .n-button .n-button__border {
    display: none;
}
.making-order-left-block .n-input-wrapper {
    padding: 16px;
}
.making-order-left-block .n-base-selection {
    padding: 16px;
    font-size: 16px;
    line-height: 100%;
    min-height: auto;
    height: 100%;
}
.making-order-left-block .n-select {
    height: 100%;
}

#making-order {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.success-making-order {
    padding: 40px 0 150px;
}
.success-making-order .container {
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center;
    text-align: center;
    color: var(--gray);
}
.success-making-order .container h1 {
    color: var(--black);
}

.success-making-order-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.success-making-order-text p {
    color: var(--black);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 4.5%;
    max-width: 450px;
}

.no-margin {
    margin-bottom: 0px;
}

.success-making-order button {
    width: max-content;
}
.promo-btn {
    padding: 10px;
    font-size: 18px;
    border: 1px solid var(--white);
}

.making-order-left-block-two-columns .n-base-selection .n-base-suffix {
    position: absolute;
    right: 0;
    top: unset;
    width: 20px;
    height: 20px;
}
.making-order-left-block-two-columns
    .n-base-selection.n-base-selection--error-status:not(
        .n-base-selection--disabled
    ).n-base-selection--focus
    .n-base-selection__state-border {
    box-shadow: none;
}
.making-order-left-block-two-columns
    .n-base-loading
    .n-base-loading__placeholder,
.making-order-left-block-two-columns .n-base-clear {
    position: static;
    transform: none;
    width: 20px;
    height: 20px;
}
.making-order-left-block-two-columns
    .n-base-selection:not(.n-base-selection--disabled).n-base-selection--active
    .n-base-selection__state-border {
    box-shadow: none;
}
.success-icon {
    width: 273px;
    height: 273px;
}

.making-order__total-btn {
    margin-top: 20px;
}

.making-order-left-deliveries {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.making-order__total-product-info-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
}
.making-order__total-product-info-header h4:last-of-type {
    display: none;
}
@media screen and (max-width: 1600px) {
    .making-order-left-block-item-info {
        max-width: 85%;
    }
}

@media screen and (max-width: 1200px) {
    .success-making-order-text p {
        font-size: 14px;
        line-height: 100%;
    }
    .making-order-left-block .n-form-item .n-form-item-feedback-wrapper {
        position: static;
        display: none;
    }
    .making-order-left-block
        .n-form-item-blank--error
        ~ .n-form-item-feedback-wrapper {
        display: block;
        margin-top: 5px;
    }
    .part-od-form {
        gap: 10px;
    }
    .making-order-left-deliveries {
        gap: 10px;
    }
    .making-order-left-block {
        gap: 20px;
    }
    .making-order-left-block .n-radio__dot-wrapper {
        margin-top: 1px !important;
    }
    .making-order-left-block-two-columns .n-form-item .n-form-item-label,
    .making-order-left-block .n-form-item .n-form-item-label {
        font-size: 14px;
    }
    .making-order-left-block-item.n-radio__dot-wrapper {
        gap: 10px;
    }
    .making-order-left-block-item-info {
        gap: 10px;
        max-width: calc(100% - 100px);
    }
    .making-order-left-block-item-subtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.12px;
        letter-spacing: 0.045em;
        text-align: left;
    }
    .making-order-content {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
    .making-order__wrapper {
        gap: 40px;
    }
    .making-order-left-block:has(.making-order-left-block-two-columns),
    .making-order-left-block.half,
    .making-order-left-block.two-thirds {
        max-width: 100%;
    }
    .making-order-left-block.two-thirds > div {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .making-order__total {
        gap: 20px;
        padding: 40px 20px;
    }
    .making-order__total-product,
    .making-order__total-product-info {
        width: 100%;
    }
    .making-order__total-product > h4 {
        display: none;
    }
    .making-order__total-product-info-header h4:last-of-type {
        white-space: nowrap;
        display: block;
    }
    .making-order__total-product-info-desc div {
        font-size: 12px;
        font-weight: 400;
        line-height: 16.39px;
        letter-spacing: 0.045em;
    }
    .making-order__total-price div {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.12px;
        letter-spacing: 0.045em;
    }
}
@media screen and (max-width: 600px) {
    .making-order-left-block-two-columns {
        flex-direction: column;
        gap: 10px;
    }
    .making-order-left-block.half {
        gap: 10px;
    }
    .making-order-left-block.half h3 {
        margin-bottom: 10px;
    }
    .making-order-left-block-two-columns
        .n-base-selection
        .n-base-selection-label {
        height: 18px;
    }
    .promo-btn {
        padding: 10px;
    }
    .success-making-order {
        padding: 40px 0 80px;
    }
    .success-making-order .container {
        gap: 40px;
    }
    .success-icon {
        width: 173px;
        height: 173px;
    }
    .success-making-order button {
        width: 100%;
    }
}
</style>
