<template>
    <div class="card-popup" :class="store.state.card_popup ? 'open' : ''">
        <div class="card-popup-bg" @click="closePopup"></div>
        <div class="card-popup-container">
            <svg
                class="card-popup-close"
                @click="closePopup"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.0672 15.1828C16.1252 15.2409 16.1713 15.3098 16.2027 15.3857C16.2342 15.4615 16.2503 15.5429 16.2503 15.625C16.2503 15.7071 16.2342 15.7884 16.2027 15.8643C16.1713 15.9402 16.1252 16.0091 16.0672 16.0672C16.0091 16.1252 15.9402 16.1713 15.8643 16.2027C15.7884 16.2342 15.7071 16.2503 15.625 16.2503C15.5429 16.2503 15.4615 16.2342 15.3857 16.2027C15.3098 16.1713 15.2409 16.1252 15.1828 16.0672L9.99998 10.8836L4.81717 16.0672C4.69989 16.1844 4.54083 16.2503 4.37498 16.2503C4.20913 16.2503 4.05007 16.1844 3.93279 16.0672C3.81552 15.9499 3.74963 15.7908 3.74963 15.625C3.74963 15.4591 3.81552 15.3001 3.93279 15.1828L9.11639 9.99998L3.93279 4.81717C3.81552 4.69989 3.74963 4.54083 3.74963 4.37498C3.74963 4.20913 3.81552 4.05007 3.93279 3.93279C4.05007 3.81552 4.20913 3.74963 4.37498 3.74963C4.54083 3.74963 4.69989 3.81552 4.81717 3.93279L9.99998 9.11639L15.1828 3.93279C15.3001 3.81552 15.4591 3.74963 15.625 3.74963C15.7908 3.74963 15.9499 3.81552 16.0672 3.93279C16.1844 4.05007 16.2503 4.20913 16.2503 4.37498C16.2503 4.54083 16.1844 4.69989 16.0672 4.81717L10.8836 9.99998L16.0672 15.1828Z"
                    fill="#8F8F8F"
                />
            </svg>
            <Preloader v-if="product == null" />
            <div class="product__main-info popup" v-else>
                <div class="product__main-info-header">
                    <h3>{{ new_title }}</h3>
                    <h3
                        class="product__main-info-price"
                        v-if="is_complect && !color_complect_price"
                    >
                        {{ price_complect + " ₽" }}
                        <h5
                            class="product__main-info-body-item-title"
                            v-if="product?.available == 'Под заказ'"
                        >
                            Под заказ
                        </h5>
                    </h3>
                    <h3
                        class="product__main-info-price"
                        v-else-if="is_complect && color_complect_price"
                    >
                        {{ color_complect_price + " ₽" }}
                        <h5
                            class="product__main-info-body-item-title"
                            v-if="product?.available == 'Под заказ'"
                        >
                            Под заказ
                        </h5>
                    </h3>
                    <h3
                        class="product__main-info-price"
                        v-else-if="!color_price"
                    >
                        {{ price + " ₽" }}
                        <h5
                            class="product__main-info-body-item-title"
                            v-if="product?.available == 'Под заказ'"
                        >
                            Под заказ
                        </h5>
                    </h3>
                    <h3 class="product__main-info-price" v-else>
                        {{ color_price + " ₽" }}
                        <h5
                            class="product__main-info-body-item-title"
                            v-if="product?.available == 'Под заказ'"
                        >
                            Под заказ
                        </h5>
                    </h3>
                </div>
                <n-scrollbar style="max-height: calc(90vh - 80px)">
                    <div class="product__main-info-body">
                        <div
                            class="product__main-info-body-item"
                            v-if="product?.categories[0]?.items"
                        >
                            <h5 class="product__main-info-body-item-title">
                                {{ product?.categories[0]?.name }}:
                            </h5>
                            <div class="product__main-info-body-item-filters">
                                <Radio
                                    @change="changeRadio(item)"
                                    :id="item.size"
                                    name="size"
                                    :value="item.size"
                                    :checked="index == 0"
                                    v-for="(item, index) in product
                                        ?.categories[0]?.items"
                                />
                            </div>
                        </div>
                        <div
                            class="product__main-info-body-item"
                            v-if="colors_info?.length > 0"
                        >
                            <h5 class="product__main-info-body-item-title">
                                {{ product?.categories[1]?.name }}:
                            </h5>
                            <div class="product__main-info-body-item-filters">
                                <template v-for="(item, index) in colors_info">
                                    <Radio
                                        v-if="item.color"
                                        :id="item.id"
                                        :color="item.color"
                                        :name="'color'"
                                        :checked="
                                            variations[0]?.color_id ==
                                            item.color_id
                                        "
                                        @change="changeRadio(item)"
                                    />
                                    <Radio
                                        v-else
                                        :id="item.id"
                                        :value="item.title"
                                        :name="'color'"
                                        :checked="
                                            variations[0]?.color_id ==
                                            item.color_id
                                        "
                                        @change="changeRadio(item)"
                                    />
                                </template>
                            </div>
                        </div>
                        <div
                            class="product__main-info-body-item"
                            v-if="
                                product?.price_kit && product?.price_kit != '0'
                            "
                        >
                            <h5 class="product__main-info-body-item-title">
                                Комплектация:
                            </h5>
                            <div class="product__main-info-body-item-filters">
                                <Radio
                                    @change="changeRadio"
                                    id="Полотно"
                                    name="kit"
                                    value="Полотно"
                                />
                                <n-tooltip trigger="hover">
                                    <template #trigger>
                                        <n-button
                                            class="product__main-info-body-item-filter"
                                            icon-placement="right"
                                        >
                                            <Radio
                                                @change="changeRadio"
                                                id="Комплект"
                                                name="kit"
                                                value="Комплект"
                                            />

                                            <template #icon>
                                                <ph-warning-circle :size="20" />
                                            </template>
                                        </n-button>
                                    </template>
                                    Полотно, коробка, наличники.
                                </n-tooltip>
                            </div>
                        </div>
                        <div class="product__main-info-body-item">
                            <h5 class="product__main-info-body-item-title">
                                Количество:
                            </h5>
                            <Counter
                                ref="counter"
                                :value="1"
                                :default="1"
                                @update:value="setCounter"
                            />
                        </div>
                    </div>
                </n-scrollbar>
                <div class="product__main-btns popup">
                    <Button @click="addProductInBasket">В корзину</Button>
                    <Button type="border" :link="'/product/' + product?.slug">
                        Открыть страницу товара
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useStore } from "vuex";
import Button from "./Button.vue";
import { NTooltip, NButton, NScrollbar } from "naive-ui";
import Radio from "./Radio.vue";
import { PhHeart, PhWarningCircle } from "@phosphor-icons/vue";
import Counter from "./Counter.vue";
import { computed, ref, watch } from "vue";
import Preloader from "../Preloader.vue";
import { numberWithSpaces } from "@/assets/js/helper";
import productApi from "@/assets/js/api/products.js";

const store = useStore();
const product_count = ref(1);

const counter = ref(null);

function closePopup() {
    store.commit("cardPopupOpen", false);
    product_count.value = 1;
    counter.value.reset();
}

const product = computed(() => {
    return store.state.productInfo;
});

const variations = computed(() => {
    return store.state.productVariants;
});

const info_product = ref({
    size: "",
    complect: false,
    color: "",
});

watch(product, (new_value) => {
    if (new_value) {
        new_title.value = new_value?.title;
        if (new_value?.categories[0].items) {
            info_product.value.size =
                new_value.categories[0].items[0].size ?? "";
        }
        if (new_value?.categories[1].items) {
            info_product.value.color =
                new_value.categories[1].items[0].id ?? "";
        }
    }
});

const is_complect = ref(false);

const new_title = ref(null);
const color_price = ref(null);
const color_complect_price = ref(null);

const color_id = ref(null);

const last_color = ref(null);

function changeRadio(item) {
    if (item.size) {
        info_product.value.size = item.size;
    }
    if (item.color || item.title) {
        info_product.value.color = item.id;

        const variant = variations.value.find(
            (variant) => variant.color_id == item.color_id
        );
        new_title.value = variant.name;
        color_id.value = variant.color_id;

        if (is_complect.value) {
            if (variant?.price && variant?.price != "0") {
                color_complect_price.value = colorful_price(variant?.price);
            }
            if (variant?.price_promo && variant?.price_promo != "0") {
                color_complect_price.value = colorful_price(
                    variant?.price_promo
                );
            }
            if (variant?.price_kit && variant?.price_kit != "0") {
                color_complect_price.value = colorful_price(variant?.price_kit);
            }
            if (variant?.price_kit_promo && variant?.price_kit_promo != "0") {
                color_complect_price.value = colorful_price(
                    variant?.price_kit_promo
                );
            }
        } else {
            if (variant?.price && variant?.price != "0") {
                color_price.value = colorful_price(variant?.price);
            }
            if (variant?.price_promo && variant?.price_promo != "0") {
                color_price.value = colorful_price(variant?.price_promo);
            }
        }

        store.commit("setAddedProductInfo", {
            img: variant.img,
            price:
                variant?.price ??
                (product.value.price_stock != "0" || product.value.price_stock
                    ? product.value.price_stock
                    : product.value.price),
        });
        last_color.value = variant;
        //   current_slide.value = variant.img;
    }
    if (item?.target?.value) {
        info_product.value.complect =
            item.target.value == "Комплект" ? true : false;
        if (info_product.value.complect) {
            is_complect.value = true;
            if (last_color.value) {
                if (last_color.value?.price && last_color.value?.price != "0") {
                    color_complect_price.value = colorful_price(
                        last_color.value?.price
                    );
                }
                if (
                    last_color.value?.price_promo &&
                    last_color.value?.price_promo != "0"
                ) {
                    color_complect_price.value = colorful_price(
                        last_color.value?.price_promo
                    );
                }
                if (
                    last_color.value?.price_kit &&
                    last_color.value?.price_kit != "0"
                ) {
                    color_complect_price.value = colorful_price(
                        last_color.value?.price_kit
                    );
                }
                if (
                    last_color.value?.price_kit_promo &&
                    last_color.value?.price_kit_promo != "0"
                ) {
                    color_complect_price.value = colorful_price(
                        last_color.value?.price_kit_promo
                    );
                }
            }
        } else {
            is_complect.value = false;
            if (last_color.value) {
                if (last_color.value?.price && last_color.value?.price != "0") {
                    color_price.value = colorful_price(last_color.value?.price);
                }
                if (
                    last_color.value?.price_promo &&
                    last_color.value?.price_promo != "0"
                ) {
                    color_price.value = colorful_price(
                        last_color.value?.price_promo
                    );
                }
            }
        }
    }
}

async function addProductInBasket() {
    let data = {};

    if (is_complect.value) {
        data = {
            date: new Date().getTime(),
            id: product.value.id,
            count: product_count.value,
            info: info_product.value,
            uniq_id:
                product.value.id +
                "_" +
                info_product.value.color +
                "_" +
                info_product.value.size +
                "_1",
            color_id: color_id.value,
        };
    } else {
        data = {
            date: new Date().getTime(),
            id: product.value.id,
            count: product_count.value,
            info: info_product.value,
            uniq_id:
                product.value.id +
                "_" +
                info_product.value.color +
                "_" +
                info_product.value.size,
            color_id: color_id.value,
        };
    }

    const result = await productApi.products_actions("basket", data);

    if (result?.success) {
        store.commit("AddedProductPopupOpen", true);
        store.commit("cardPopupOpen", false);
        store.commit("AddedProductCount", product_count.value ?? 1);
        store.commit("setAddedProductInfo", result?.products[0]);

        YMTargetSend("korzina");
        counter.value.reset();
    }
}

const price = computed(() => {
    let checked_color;
    colors_info.value.forEach((item) => {
        if (variations.value[0]?.color_id == item.color_id) {
            checked_color = item;
        }
    });

    console.log(checked_color);

    const variant = variations.value.find(
        (variant) => variant.color_id == checked_color.color_id
    );

    if (variant) {
        if (variant?.price_promo && variant?.price_promo != "0") {
            return numberWithSpaces(variant?.price_promo);
        }
        if (variant?.price && variant.price != "0") {
            return numberWithSpaces(variant?.price);
        }
    } else {
        if (product.value?.price_stock && product.value?.price_stock != "0") {
            return numberWithSpaces(product.value?.price_stock);
        }
        if (product.value?.price && product.value?.price != "0") {
            return numberWithSpaces(product.value?.price);
        }
    }
});

const price_complect = computed(() => {
    let checked_color;
    colors_info.value.forEach((item) => {
        if (variations.value[0]?.color_id == item.color_id) {
            checked_color = item;
        }
    });

    const variant = variations.value.find(
        (variant) => variant.color_id == checked_color.color_id
    );

    if (variant) {
        if (variant?.price_kit_promo && variant?.price_kit_promo != "0") {
            return numberWithSpaces(variant.price_kit_promo);
        }
        if (variant?.price_kit && variant?.price_kit != "0") {
            return numberWithSpaces(variant?.price_kit);
        }
        if (variant?.price_promo && variant?.price_promo != "0") {
            return numberWithSpaces(variant?.price_promo);
        }
        if (variant?.price && variant?.price != "0") {
            return numberWithSpaces(variant?.price);
        }
    } else {
        if (product?.value?.price_kit && product?.value?.price_kit != "0") {
            return numberWithSpaces(product?.value?.price_kit);
        }
        if (product?.value?.price_stock && product?.value?.price_stock != "0") {
            return numberWithSpaces(product?.value?.price_stock);
        }
        if (product?.value?.price && product?.value?.price != "0") {
            return numberWithSpaces(product?.value?.price);
        }
    }
});

product_count.value = product.value?.count;

function setCounter(value) {
    product_count.value = value;
    //  let data = {
    //      id: product.value.id,
    //      count: product_count.value,
    //  };

    //  // data = {
    //  //     date: new Date().getTime(),
    //  //     products: [{ id: product.value.id, count: product_count.value }],
    //  // };
    //  // await productApi.products_actions("basket", data);
}

function colorful_price(price) {
    return numberWithSpaces(price);
}

const colors_info = ref([]);

watch(product, (new_colors) => {
    if (new_colors?.categories) {
        colors_info.value = new_colors?.categories[1]?.items;
        if (colors_info.value) {
            colors_info.value = colors_info.value.filter(
                (item) => item.title || item.color
            );
        }
    }
});
</script>
<style>
.card-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    z-index: 100;
}

.card-popup.open {
    opacity: 1;
    pointer-events: all;
}

.card-popup-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.card-popup-container {
    max-height: 90vh;
    max-width: 800px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 60px;
    background-color: white;
    border-radius: 5px;
    padding: 40px;
}

.card-popup-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 10;
    transition: 0.5s;
}

.card-popup-close path {
    transition: 0.5s;
}

.card-popup-close:hover path {
    fill: black;
}

.product__main-info.popup {
    max-height: calc(90vh - 80px);
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.product__main-btns.popup {
    flex-direction: column;
}

.card-popup .radio-btn label {
    font-size: 14px;
    font-weight: 500;
}

.card-popup .product__main-info-body-item-filters .n-button {
    padding: 0;
}

.card-popup .product__main-info-body {
    margin-bottom: 20px;
    padding-right: 20px;
}

@media screen and (max-width: 800px) {
    .card-popup {
        padding: 0;
    }

    .card-popup-container {
        height: 100dvh;
        width: 100%;
        max-height: none;
        max-width: none;
        padding: 20px;
    }

    .product__main-info.popup {
        max-height: calc(100vh - 40px);
    }
}

@media screen and (max-width: 500px) {
    .card-popup-container {
        padding: 20px;
    }

    .card-popup .product__main-info-price {
        display: block;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
    }

    .product__main-btns.popup {
        margin-top: 20px;
    }
}
</style>
